import {createContext, FC, ReactElement, ReactNode, useContext} from "react";
import {RootStore, RootStoreModel} from "./store";

const StoreContext = createContext<RootStoreModel>({} as RootStoreModel);

export const useStores = (): RootStore => useContext(StoreContext);

export type StoreComponent = FC<{
    store: RootStoreModel;
    children: ReactNode;
}>;

export const StoreProvider: StoreComponent = ({
                                                  children,
                                                  store
                                              }): ReactElement => {
    return (
        <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    )
}
