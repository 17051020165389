/**
 * Import all your stores
 */
import ApiKeyStore from "./stores/apiKeyStore";
import GroceryStore from "./stores/groceryList";

export interface RootStoreModel {
    apiKeyStore: ApiKeyStore;
    groceryStore: GroceryStore;
}

/**
 * Root Store Class with
 */
export class RootStore {
    apiKeyStore: ApiKeyStore;
    groceryStore: GroceryStore;

    constructor() {
        this.apiKeyStore = new ApiKeyStore(this); // Pass `this` into stores for easy communication
        this.groceryStore = new GroceryStore(this); // Pass `this` into stores for easy communication
    }
}

export const store = new RootStore();
