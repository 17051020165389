import Product from "../model/Product";
import PickState from "../model/PickState";
import productJSON from '../assets/producten.json'

export const getProducts = async () => {

    const products: any = productJSON;

    return products.map((p: Product) => {
        p.count = 0;
        p.picked = PickState.unpicked;
        p.show = true;
        return p;
    })

}
