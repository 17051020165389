export default class ApiService {
    apiKey: string;
    defaultHeaders: any;
    webApiUrl: string;

    constructor(apiKey: string, webApiUrl: string) {
        this.apiKey = apiKey;
        this.webApiUrl = webApiUrl;
        this.defaultHeaders = new Headers();
        this.defaultHeaders.append("Content-Type", "application/json");
        this.defaultHeaders.append("Boodschappen-Auth", this.apiKey);
    }


    async Get(urlParams: string) {
        const options = {
            method: "GET",
            headers: this.defaultHeaders
        }

        try {
            const request = new Request(this.webApiUrl + "?" + urlParams, options);
            const response = await fetch(request);
            return response.json();
        } catch (e) {
            console.error(JSON.stringify(e));
            return [];
        }

    }

    async Post(model: any) {
        var options = {
            method: "POST",
            headers: this.defaultHeaders,
            body: JSON.stringify(model)
        }
        const request = new Request(this.webApiUrl, options);
        const response = await fetch(request);
        return response;
    }

    async Put(model: any) {
        var options = {
            method: "PUT",
            headers: this.defaultHeaders,
            body: JSON.stringify(model)
        }
        const request = new Request(this.webApiUrl, options);
        const response = await fetch(request);
        return response;
    }

    async Delete(id: string) {
        const options = {
            method: "DELETE",
            headers: this.defaultHeaders
        }
        const request = new Request(this.webApiUrl + "/" + id, options);
        const response = await fetch(request);
        return response;
    }
}




