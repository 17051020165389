import React from 'react';
import './GroceryListItem.css';
import Product from "../../model/Product";
import PickState from "../../model/PickState";
import categoryIcons from "../../helpers/GroceryCategoryHelper";

type Props = {
    product: Product,
    setPickState: (pickState: PickState) => void
    setPickCount: (count: number) => void
}

class GroceryListItem extends React.Component<Props> {

    nextPickState() {
        let nextState: PickState;
        switch (this.props.product.picked) {
            case PickState.unpicked:
                nextState = PickState.picked;
                break;
            case PickState.picked:
                nextState = PickState.unavailable;
                break;
            case PickState.unavailable:
                nextState = PickState.differentPick;
                break;
            case PickState.differentPick:
                nextState = PickState.unpicked;
                break;
        }
        return nextState;
    }

    render() {
        return (

            <div className={`GroceryListItem 
            ${this.props.product.show? '': "hide"}
            ${this.props.product.picked === PickState.picked ? "picked" : ""}
            ${this.props.product.picked === PickState.differentPick ? "differentPick" : ""}
            ${this.props.product.picked === PickState.unavailable ? "unavailable" : ""}
            
            
            }`}>
                <div>
                    <div className={`   ${this.props.product.count > 0 ? "shouldPick" : "noPick"}`}></div>

                    <div className="pickState">
                        <h2 className={`${this.props.product.picked === PickState.picked ? "pickicon--picked" : "hide"}`}>✔</h2>
                        <h2 className={`${this.props.product.picked === PickState.unavailable ? "pickicon--unavailable" : "hide"}`}>X</h2>
                        <h2 className={`${this.props.product.picked === PickState.differentPick ? "pickicon--differentPick" : "hide"}`}>✔</h2>
                    </div>

                    <p className="productName" onClick={(e) => {
                        this.props.setPickState(this.nextPickState())
                    }}>
                        {categoryIcons[this.props.product.category]}
                        &nbsp;
                        {this.props.product.count} &nbsp; {this.props.product.name}
                    </p>
                </div>
                <div>

                    <div className="buttonGroup">
                        <div className="plusCount"
                             onClick={(e) => {
                                 this.props.setPickCount(this.props.product.count + 1)
                             }}
                        ><p>+</p></div>

                        <div className="minCount"
                             onClick={(e) => {
                                 this.props.setPickCount(this.props.product.count - 1);
                             }}
                        ><p>-</p></div>
                    </div>

                    {/*<div className="trash"*/}
                    {/*     onClick={(e) => {*/}
                    {/*         this.props.setPickCount(0);*/}
                    {/*     }}*/}
                    {/*>*/}
                    {/*    <h2>🗑</h2>*/}
                    {/*</div>*/}


                </div>
            </div>
        )
    }

}

export default GroceryListItem
