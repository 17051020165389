const categoryIcons : {
    [key: string]: any
} = {
    meat: '🍖',
    pasta: '🍝',
    coffee_and_tea: '🍵',
    breakfast: '🥯',
    snack: '🍬',
    bakery: '🥐',
    spread: '🧈',
    fruit: '🍏',
    dairy: '🥛',
    vegetables: '🥕',
    pizza: '🍕',
    tapas: '🌶',
    salad: '🥗',
    cheese: '🧀',
    toiletry: '🧻',
    cookies: '🍪',
    flowers: '🌷',
    potatoes: '🥔',
    soup: '🥣',
    spices: '🌿',
    baking: '🪔',
    soda: '🥤',
    beer: '🍺',
    wine: '🍷',
    rice: '🍚',
    sauce: '🧉',
    eggs: '🥚',
    cake: '🍰',
    chocolate: '🍫',
    ice: '🍦',
    fish: '🐟',
    laundry: '🧺',
    frying: '🍟',
    oven: '♨'
}

export default categoryIcons;

