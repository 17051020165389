import {useStores} from "../store/provider";
import {observer} from "mobx-react";


const Login = observer(() => {
    const {apiKeyStore} = useStores();

    return (
        <>
            <h1>Login</h1>
            <form onSubmit={(e: any) => {
                e.preventDefault();
                apiKeyStore.setApiKey(e.target.elements.apiKey.value);
            }}>
                <label>
                    Apikey:
                    <textarea name="apiKey"
                    />
                </label>
                <input type="submit" value="Submit"/>
            </form>
            <p>Laatste verficatie:  {new Date(apiKeyStore.verifiedAt).toString()}</p>

        </>

    )
})

export default Login;
