import './App.css';
import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Layout from "./pages/Layout";
import {observer} from "mobx-react";

const App = () => {


    return (
        <div className="App">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Layout/>}>
                            {/*<Route path="/" element={<Layout />}>*/}
                            <Route index element={<Home/>}/>

                            <Route path="login" element={<Login/>}/>
                            {/*<Route path="blogs" element={<Blogs />} />*/}
                            {/*<Route path="contact" element={<Contact />} />*/}
                            {/*<Route path="*" element={<NoPage />} />*/}
                            {/*</Route>*/}
                        </Route>
                    </Routes>
                </BrowserRouter>
        </div>
    );
}

export default App;
