import {Link, Outlet} from "react-router-dom";
import React from "react";
import './Layout.css';
import {useStores} from "../store/provider";
import {observer} from "mobx-react";

const Layout = observer(() => {

    const {apiKeyStore} = useStores();
    apiKeyStore.verifyApiKey();

    return (<div className="Layout">
            <header>

                <nav>
                    <ul>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/login">Login &nbsp;
                                <span
                                    className={`login-icon ${apiKeyStore.verified? 'verified': 'rejected'}`}></span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </header>
            <Outlet/>
        </div>
    )
});

export default Layout;
