import {makeAutoObservable} from 'mobx';
import {RootStoreModel} from "../store";

export interface IApiKeyStore {
    apiKey: string;
    verified: boolean;
    verifiedAt: number;
}

export const EXP = 3600000 * 24;

export default class ApiKeyStore implements IApiKeyStore {
    apiKey: string = '';
    verified: boolean = false;
    verifiedAt: number = 0;
    status: string = 'idle';
    private rootStore: RootStoreModel;

    constructor(rootStore: RootStoreModel) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
        this.loadApiKey();
        this.loadVerifiedAt();
    }

    loadApiKey(): void {
        this.apiKey = localStorage.getItem('apiKey') ? localStorage.getItem('apiKey')! : '';
    }

    loadVerifiedAt(): void {
        // @ts-ignore
        this.verifiedAt = localStorage.getItem('apiKeyVerifiedAt') ? parseInt(localStorage.getItem('apiKeyVerifiedAt'))! : 0;
        if (this.verifiedAt > Date.now() - EXP) {
            this.verified = true;
        }
    }

    setApiKey(apiKey: string): void {
        this.apiKey = apiKey;
        localStorage.setItem('apiKey', apiKey);
    }

    setVerified(verified: boolean): void {
        this.verified = verified;
    }

    setVerifiedAt(verifiedAt: number): void {
        this.verifiedAt = verifiedAt;
        localStorage.setItem('apiKeyVerifiedAt', verifiedAt.toString());
    }

    setStatus(status: string): void {
        this.status = status;
    }

    get isVerified(): boolean {
        return this.verified;
    }

    async verifyApiKey(key?: string): Promise<void> {
        if (this.status === 'pending') return;

        if ((Date.now() - this.verifiedAt) < EXP) {
            return;
        }

        if (key === '') {
            this.setVerified(false);
        } else {
            this.setStatus('pending');
            if (key) this.setApiKey(key);
            const response = await fetch('https://boodschappen-app.wereldbolt.workers.dev/verify?datetime=' + Date.now(), {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Boodschappen-Auth": this.apiKey,
                },
            });

            if (response.status === 200) {
                this.setVerified(true);
            } else {
                this.setVerified(false);
            }
            this.setVerifiedAt(Date.now());

            this.setStatus('idle');
        }
    }
}
