import {makeAutoObservable, toJS} from 'mobx';
import {RootStoreModel} from "../store";
import ApiService from "../../api/ApiService";
import GroceryList from "../../model/GroceryList";
import React from "react";
import Product from "../../model/Product";
import PickState from "../../model/PickState";

export interface IGroceryStore {
    currentGroceryList: GroceryList | null;
    currentGroceryListId: string;
    groceryLists: string[]
}

const webApiUrl = "https://boodschappen-app.wereldbolt.workers.dev";

export default class GroceryStore implements IGroceryStore {
    currentGroceryList: GroceryList | null = null;
    currentGroceryListId: string = '';
    groceryLists: string[] = [];
    private rootStore: RootStoreModel;
    private groceryApiService: ApiService;
    private pickApiService: ApiService;

    constructor(rootStore: RootStoreModel) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
        const groceryApiUrl = webApiUrl + "/grocerylist";
        this.groceryApiService = new ApiService(this.rootStore.apiKeyStore.apiKey, groceryApiUrl);
        const pickApiUrl = webApiUrl + "/pick";
        this.pickApiService = new ApiService(this.rootStore.apiKeyStore.apiKey, pickApiUrl);
        this.loadLists();
        this.loadCurrentGroceryListId();

    }

    loadLists(): void {
        // @ts-ignore
        if (localStorage.getItem('groceryLists') === null) {
            this.storeLists();
        } else {
            // @ts-ignore
            this.groceryLists = JSON.parse(localStorage.getItem('groceryLists'));
        }
    }

    storeLists(): void {
        localStorage.setItem('groceryLists', JSON.stringify(this.groceryLists));
    }

    loadCurrentGroceryListId(): void {
        this.currentGroceryListId = localStorage.getItem('currentGroceryListId') ? localStorage.getItem('currentGroceryListId')! : '';
        if (this.currentGroceryListId === '') {
            if (this.groceryLists.length > 0) {
                this.currentGroceryListId = this.groceryLists[0];
            } else {
                this.currentGroceryListId = this.newGroceryList();
            }
            this.storeCurrentGroceryListId();
        }
    }

    storeCurrentGroceryListId(): void {
        localStorage.setItem('currentGroceryListId', this.currentGroceryListId);
    }

    newGroceryList(): string {
        const id = Math.random().toString(36).substring(2, 7);
        this.groceryLists.push(id);
        this.storeLists();
        return id;
    }

    async getGroceryList(id: string, setAsCurrent: boolean = false): Promise<GroceryList> {
        const list = await this.groceryApiService.Get(`id=${id}`);
        if (setAsCurrent) this.currentGroceryList = list;
        // @ts-ignore
        return list;
    }

    /**
     * Get the list ids, the current list is always first
     */
    get getSortedListIds(): string[] {
        return this.groceryLists.slice()
            .sort((gl: string) => gl === this.currentGroceryListId ? 1 : -1);
    }

    setPickState(p: Product, state: PickState): void {
        if (this.currentGroceryList) {
            if (this.currentGroceryList.products.length !== 0) {
                this.currentGroceryList.products.find((p) => p.name === p.name)!.pickState = state;
                this.pickApiService.Put({
                    id: this.currentGroceryList.id,
                    product: p
                });
            }else{
                console.error("No products in current grocery list");
            }
        } else {
            console.error("No current grocery list");
        }
    }

    setPickCount(p: Product, count: number): void {
        if (this.currentGroceryList) {
            if (this.currentGroceryList.products.length !== 0) {
                this.currentGroceryList.products.find((p) => p.name === p.name)!.count = count;
                this.pickApiService.Put({
                    id: this.currentGroceryList.id,
                    product: p
                });
            }else{
                console.error("No products in current grocery list");
            }
        } else {
            console.error("No current grocery list");
        }
    }
}
