import React from "react";
import './GroceryListFilter.css';
import categoryIcons from "../../helpers/GroceryCategoryHelper";

type Props = {
    showFilter: boolean
    toggleFilter: () => void,
    categories: { [key: string]: boolean }
    setCategoryCheck: (category: string) => void
}

class GroceryListFilter extends React.Component<Props> {

    render() {
        return (
            <div className={`GroceryListFilter ${this.props.showFilter ? '' : 'hide'}`}>
                <div className="GroceryListFilterHeader">
                    <h2>Filtersss</h2>
                </div>
                <div className="GroceryListItemCollection">
                    <div>
                        {Object.keys(this.props.categories).map((c: string, index: number) => {
                            return <div
                                className="GroceryListFilterItem"
                                key={index}
                            >
                                <input
                                    type="checkbox"
                                    id={index.toString()}
                                    name={c}
                                    value={this.props.categories[c] ? 'checked' : ''}
                                    onChange={(e) => {
                                        this.props.setCategoryCheck(c)
                                    }}
                                />
                                <label htmlFor={index.toString()}>
                                    {categoryIcons[c]} &nbsp;
                                    {c}
                                </label>
                            </div>

                        })}
                    </div>
                </div>
                <button className="btnCloseGroceryListFilter" onClick={this.props.toggleFilter}>Sluiten</button>

            </div>
        )


    }
}

export default GroceryListFilter;
