import GroceryListFunc from "../components/GroceryList/GroceryListFunc";
import React from "react";

const Home = () => {

    return (
        <GroceryListFunc/>

    )

}

export default Home;
