import GroceryListItem from './GroceryListItem';
import GroceryListFilter from './GroceryListFilter';
import './GroceryList.css';
import Product from "../../model/Product";
import PickState from "../../model/PickState";
import {getProducts} from "../../api/product";
import React, {useRef} from "react";
import {useState, useEffect} from "react";
import GroceryList from "../../model/GroceryList";
import {useStores} from "../../store/provider";
import {observer} from "mobx-react";

const sortGroceryListItems = (arr: Product[]) => {
    return arr.sort((a, b) => a.path - b.path);
}

const retrieveProductsFromLocalStorage = () => {
    let storageString = localStorage.getItem('pickCount');
    if (storageString) {
        return JSON.parse(storageString);
    }
    return [];
}

const storeProductInLocalStorage = (product: Product) => {
    let storageArr;
    let storageObj = {
        name: product.name,
        count: product.count,
        picked: product.picked
    }

    let storageStringArr: null | string | [] = localStorage.getItem('pickCount');
    if (!storageStringArr) {
        storageArr = [];
        storageArr.push(storageObj);
    } else if (storageStringArr === '') {
        storageArr = [];
        storageArr.push(storageObj);
    } else {
        storageArr = JSON.parse(storageStringArr);
        const index = storageArr.findIndex((el: any) => el.name === product.name);
        if (index !== -1) {
            storageArr[index] = storageObj;
        } else {
            storageArr.push(storageObj);
        }
    }

    localStorage.setItem('pickCount', JSON.stringify(storageArr))
}


const GroceryListFunc = observer(() => {

    const {groceryStore} = useStores()


    const [searchValue, setSearchValue] = useState('');
    const [showFilter, setShowFilter] = useState(false);
    const [showPickList, setShowPickList] = useState(false);
    const [products, setProducts]: [Product[], any] = useState([]);
    const [checkedCategories, setCheckedCategories]: [any, any] = useState({});


    /**
     * Initial data fetch
     */
    let initialRender = useRef(true);
    useEffect(() => {

            const fetchData = async () => {

                let products: Product[] = await getProducts();

                // let groceryList: GroceryList =  await groceryStore
                //      .getGroceryList(groceryStore.currentGroceryListId, true);
                let productsFromStorage = retrieveProductsFromLocalStorage();
                let categorySet: Set<string> = new Set();
                for (const p of products) {
                    let countObj = productsFromStorage.find((el: any) => el.name === p.name);
                    if (countObj) {
                        p.count = countObj.count;
                        p.picked = countObj.picked;
                    }
                    categorySet.add(p.category);
                }
                setProducts(sortGroceryListItems(products));
                setCheckedCategories(Array.from(categorySet).reduce((p: { [key: string]: boolean }, c) => {
                    p[c] = false
                    return p;
                }, {}))
            }
            fetchData()
                .then(r => initialRender.current = false)
                .catch(err => console.log(err));
        }, [initialRender]
    )

    /**
     * Observe showPickList en change state of product list
     */
    useEffect(() => {
        setProductState(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPickList, checkedCategories, searchValue])

    const setProductState = (resetPickCount: boolean = false) => {
        const filterEnabled: boolean =
            Object.keys(checkedCategories)
                .reduce((p, c) => {
                    // @ts-ignore
                    if (p === false && checkedCategories[c]) return true;
                    return p;
                }, false)

        // state products
        const arr: Product[] = [...products]
            .map(p => {

                // reset pickcount
                if (resetPickCount) {
                    p.count = 0;
                    p.picked = PickState.unpicked;
                    localStorage.removeItem('pickCount');
                }

                // reset show
                p.show = true;

                return p;
            })
            .map(p => {
                if (
                    searchValue !== '' &&
                    p.name.indexOf(searchValue.toLowerCase()) === -1 &&
                    p.category.indexOf(searchValue.toLowerCase()) === -1
                ) p.show = false;
                return p;
            })
            .map(p => {

                //category filter
                if (filterEnabled) {
                    p.show = checkedCategories[p.category];
                }
                return p
            })
            .reduce((p: Product[], c: Product) => {
                if (showPickList && c.count === 0) {
                    c.show = false;
                }
                p.push(c);
                return p;
            }, [])
            .sort((a, b) => a.path - b.path);
        setProducts(arr);
    }

    const toggleFilter = () => {
        setShowFilter(!showFilter);
    }

    const togglePickList = () => {
        setShowPickList(!showPickList);
    }

    const setCategoryCheck = (category: string) => {
        setCheckedCategories
        ({
            ...checkedCategories,
            [category]: !checkedCategories[category]
        })
    }

    const setPickState = (p: Product, state: PickState) => {
        p.picked = state;
        replaceProductInStateArr(p);
        storeProductInLocalStorage(p);
        groceryStore.setPickState(p, state);

    }

    const setPickCount = (p: Product, count: number) => {
        p.count = count;
        replaceProductInStateArr(p);
        storeProductInLocalStorage(p);
    }

    const replaceProductInStateArr = (p: Product) => {
        const index = products.findIndex(el => el.name === p.name);
        const newArr = [...products];
        newArr[index] = p;
        setProducts(sortGroceryListItems(newArr));
    }

    const searchProduct = (e: any) => {
        setSearchValue(e.target.value);
    }

    const createGroceryList = () => {
        console.error('createGroceryList not implemented yet');
        // const gl = CreateGroceryList();
        // setCurrentGroceryListId(gl.id);
        // storeCurrentGroceryListId(gl.id);
        // setGroceryLists([...groceryLists, gl]);

        //     let index = groceryLists.findIndex((gl: GroceryList) => gl.id === select(currentGroceryListId))
        //     let dropDown = document.getElementById("groceryListsSelect");
        //     // @ts-ignore
        //     dropDown.selectedIndex = index;
    }

    const list = products
        .reduce((p, c, index, a) => {
                if (
                    showPickList &&
                    c.show &&
                    c.path.toString() !== p.currentPath

                ) {
                    // @ts-ignore
                    p.items.push(<div key={`divider-${index}`} className="pathDivider"><p>{c.path.toString()}</p></div>)
                    p.currentPath = c.path.toString();
                }

                if (c.show) {
                    // @ts-ignore
                    p.items.push(<GroceryListItem
                        key={index}
                        product={c}
                        setPickState={(state: PickState) => {
                            setPickState(c, state,)
                        }}
                        setPickCount={(count) => {
                            setPickCount(c, count)
                        }}
                    />);
                }

                return p;
            },
            {
                items: [],
                currentPath: ''
            }
        ).items;

    return (
        <div className="GroceryList">
            <div className="filterButtons">

                <button onClick={() => {
                    setProductState(true)
                }}>Reset
                </button>
                <button onClick={(e) => {
                    togglePickList()
                }}
                >
                    {showPickList ? 'Show all' : 'Show picklist'}
                </button>
                <button onClick={(e) => {
                    toggleFilter()
                }}>
                    {showFilter ? 'Hide filter' : 'Show filter'}
                    &nbsp;
                    <small>(
                        {Object.keys(checkedCategories).reduce((p, c) => {
                            if (checkedCategories[c]) p++;
                            return p;
                        }, 0).toString()}
                        )
                    </small>
                </button>

                <button onClick={createGroceryList}>New</button>
            </div>

            <GroceryListFilter
                showFilter={showFilter}
                toggleFilter={() => {
                    toggleFilter()
                }}
                categories={checkedCategories}
                setCategoryCheck={(categoryCheck) => {
                    setCategoryCheck(categoryCheck);
                }}
            />
            <div className='titleBar'>
                <h1>Grocery List

                    <small className={`pickCounter ${showPickList ? '' : 'hide'}`}>
                        {products.filter(
                            p => p.picked === PickState.picked ||
                                p.picked === PickState.unavailable ||
                                p.picked === PickState.differentPick).length.toString()
                        }/{products.filter(p => p.count > 0).length.toString()}
                    </small>
                </h1>

                <select name="groceryLists" id="groceryListsSelect">
                    {
                        groceryStore.getSortedListIds
                            .map((gl: string) => {
                                return <option key={`"select-${gl}`} value={gl}>{gl}</option>
                            })
                    }
                </select>
            </div>

            <div className="searchBar">
                <input
                    placeholder={'Zoek op product of categorie'}
                    value={searchValue}
                    onChange={(e) => {
                        searchProduct(e)
                    }}
                    onKeyDown={(event) => {
                        if (event.key === "Backspace" && searchValue !== '') {
                            setSearchValue('');
                        }
                    }}

                    type="text"/>
                <button onClick={(e) => {
                    setSearchValue('');
                }}

                >X
                </button>
            </div>
            <div>
                {list}
            </div>
        </div>
    )
})

export default GroceryListFunc;
